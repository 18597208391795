import React, {useEffect, useState} from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import {metadata} from '../../config'
import {
  adDisplayDelay,
  delayMultiplicationFactor,
  isBrowser,
  setStreamData
} from '../utils/articleUtil'
import {isMobile} from 'react-device-detect'
import {map as _map} from 'lodash'
import {homePageAds} from '../utils/adUnits'
import {addTimeout} from '../utils/timeManager'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  ArticleCard,
  ArticlePreview,
  ArticlesComplexLayout,
  ArticlesHorizontalLayout
} from '@cg-squad/ui-components'
import readingTime from '@danieldietrich/reading-time'

const Homepage = props => {
  const atfArticles = [];

  const [isMobileDisplay, setIsMobileDisplay] = useState(false);
    useEffect(() => {
        setIsMobileDisplay(isMobile);
        /*addTimeout(() => {
          homePageAds();
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));*/
      addTimeout(homePageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    }, [isMobile])

    useEffect(() => {
      if (isBrowser()) {
        const links = document.querySelectorAll('.excerpt-link');
        links.forEach((node) => {
          node.addEventListener('click', function(e){
            e.stopPropagation();
          })
        })
      }
    }, [])

  const featuredArticle = (props.data.featured.nodes || [])
    .find(article => new Date(article['featuredTill']) >= new Date());
  if (featuredArticle) {
    if (featuredArticle.excerptNode.childMarkdownRemark.html !== "") {
      featuredArticle.excerptHtml = featuredArticle.excerptNode.childMarkdownRemark.html;
      featuredArticle.excerptHtml = featuredArticle.excerptHtml.replaceAll('<a', '<a class="excerpt-link" target="_blank"');
    }
    const {minutes} = readingTime(featuredArticle.content.replace(/<blockquote[\s\S]*?<\/blockquote>/g, ''));
    featuredArticle.readingTime = minutes;
    atfArticles.push(featuredArticle);
  }

  props.data.topRight.nodes.forEach(article => {
    if (article.slug !== featuredArticle.slug) {
      atfArticles.push(article);
    }
  });

  const editorsArticles = atfArticles.slice(9, 14);
  const youShouldKnowArticles = atfArticles.slice(14, 18);
  const bigCarousalArticles = atfArticles.slice(18, 23);

  const newsLetterRow = () => {
    return <div className="flex flex-col lg:flex-row items-center justify-center border-b py-6">
      <div className="font-libre text-3xl">{metadata.defaultTitle.replace(' ', '')} Newsletter</div>
      <div className="text-lg mx-4 text-center leding-none">
        Sign up to receive the top stories you need to know right now.
      </div>
      {isBrowser() && <div className="subscribe-button border-teal border px-5 py-2 rounded-2xl mt-3 lg:mt-0 cursor-pointer" onClick={() => {
        window.openSubscribePopup();
      }}>
        SUBSCRIBE
      </div>}
    </div>
  }

    const schemaArticle = {
        '@context': 'https://schema.org',
        '@type': 'Website',
        "url": metadata.url,
        "name": metadata.defaultTitle,
        "description": metadata.description,
        "publisher": {
          "@type": "Organization",
          "name": metadata.defaultTitle,
          "logo": {
            "@type": "ImageObject",
            "url": `${metadata.url}/images/logos/logo.jpeg`
          }
        }
    }

    setStreamData({
        category: undefined,
        subCategory: undefined,
        article: undefined,
        author: undefined
    })

  return <Layout>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>
      <link rel="canonical" href={metadata.url}/>
    </Helmet>
    <main className={"wrapper lg:mx-auto main-data-container"} data-datocms-noindex>
      <ArticlesComplexLayout articles={atfArticles.slice(0, 9)}
                             imageLoading="eager"/>
      <div className="pb-6 border-b">
        <div className="flex justify-center">
          <div className="relative font-libre text-4xl mb-4 mt-2 text-center">
            Editor's Pick
            <div className="absolute -right-3 bottom-2" style={{zIndex: -1}}>
              <img src="/images/logos/ag-icon.svg" width="50" height="47" alt="age group icon"/>
            </div>
          </div>
        </div>
        <div className="lg:hidden editors-choice">
          <Carousel
            infiniteLoop
            centerMode
            centerSlidePercentage={62}
            showIndicators={false}
            showStatus={false}
            dynamicHeight={false}
            showThumbs={false}
          >
            {editorsArticles.map((article, index) => {
              return <ArticleCard
                key={`carousel-${index}`} data={article}
                article={article}
                imageClass="mb-2"
                gatsbyImageClass="w-full h-[376px]"
                headingClass="text-2xl mb-0 line-clamp-2"/>
            })}
          </Carousel>
        </div>
        <div className="hidden lg:grid grid-cols-5 gap-4">
          {editorsArticles.map((article, index) => {
            return <ArticleCard
              key={`editors-${index}`} data={article}
              article={article}
              imageClass="mb-2"
              gatsbyImageClass="w-full h-[376px]"
              headingClass="text-lg mb-0 line-clamp-2"/>
          })}
        </div>
      </div>
      {/*{newsLetterRow()}*/}
      <div className="py-6 lg:py-8 mb-4 lg:mb-6 border-b">
        <p className="font-libre text-4xl mb-4 text-center">You Should Know</p>
        <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-6">
          {youShouldKnowArticles.map((article, index) => {
            return <ArticlePreview
              key={`you-should-know-${index}`} data={article}
              className={`col-span-1 mb-3 border-b pb-3 lg:mb-0 lg:border-0 lg:p-0 ${index % 2 === 0 ? 'border-r pr-3' : 'pl-3'}`}
              imageClass="mb-2"
              gatsbyImageClass="w-full h-[160px] lg:h-[228px]"
              headingClass="font-outfit font-normal leading-7 text-2xl mb-0 lg:mb-[2px] line-clamp-2"
              noBody={true}
              noFlag={true}
              noAuthor={true}
              noReadMore={true}
              authorAndReadingTime={true}/>
          })}
        </div>
      </div>
      <div className="mb-4 lg:mb-6">
      <p className="font-libre text-3xl lg:text-4xl mb-4 text-center">Recommended Reading</p>
        <Carousel
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          dynamicHeight={false}
          showThumbs={false}
          autoPlay={true}
          interval={5000}
        >
          {bigCarousalArticles.map((article, index) => {
            return <ArticleCard
              key={`carousel-${index}`} data={article}
              article={article}
              imageClass="mb-2"
              gatsbyImageClass="w-full h-[362px] lg:h-[465px]"
              headingClass="text-3xl mb-0 line-clamp-2 w-10/12 mx-auto"
              contentClass="mx-auto w-10/12 lg:w-2/3 left-0 right-0 text-center mb-20"
              categoryClass="text-white mb-1 text-2xl"
              showExcerpt={true}/>
          })}
        </Carousel>
      </div>
      <div className="ad-container mb-2" data-datocms-noindex>
        <div className="advertisement-text">Advertisement</div>
        <div id="ht_incontent1"></div>
      </div>

      {_map(props.pageContext.indexPageCategoryWiseArticles, (data, key) => {
        const category = {
          title: data.title,
          slug: key
        }
        if (data.index % 2 === 0) {
          return <ArticlesComplexLayout articles={data.articles}
                                        category={category}
                                        rightCount={5}/>
        }
        return <ArticlesHorizontalLayout articles={data.articles}
                                         category={category}/>;
      })}
      <div className="ad-container mb-2" data-datocms-noindex>
        <div className="advertisement-text">Advertisement</div>
        <div id="ht_incontent2"></div>
      </div>
    </main>
  </Layout>
}

export const query = graphql`
    query indexPageTopLeft($currentDateTime: Date) {
        featured: allDatoCmsArticle(filter: {featuredTill: {gte: $currentDateTime}, website: {elemMatch: {name: {eq: "ht"}}},noIndex: {ne: true}}
            sort: {publishedAt: DESC}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        topRight: allDatoCmsArticle(filter: {category: {elemMatch: {slug: {regex: "/^((?!-video).)*$/"}}}, website: {elemMatch: {name: {eq: "ht"}}}, noIndex: {ne: true}}
            sort: {publishedAt: DESC}
            limit: 25
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default Homepage
